import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { first, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { convertPayrollDataToCSV } from '@careerstart/wae-common/helpers';
import {
  ONE_SECOND_IN_MS,
  ONE_WEEK_IN_MS,
} from '@careerstart/wae-common/src/main/constants/timeConversions';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { apiGet } from '../../../../datahub/axios';
import { searchParamOptions } from '../../../../main/views/app/routeConstants';
import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import WaePaginatedDataGrid from '../../../components/DataGrid';
import DetailView from '../../../components/DetailView/DetailView2';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectSelectedTimeCard,
  selectTimecardActionIsProcessing,
  selectTimeCards,
  selectTimeCardsIsProcessing,
  selectTimeSheetGridTotalRowCount,
} from '../../../store/selectors/timeSheetSelector';
import { dataGridFiltersHeight, listHeaderHeight, topBarHeight } from '../../../theme';
import { epochToDateInReadableFormat, isSearchParamValid } from '../../../utils';
import { useDims } from '../../../utils/customHooks';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';
import { addOneWeek, getSunday, getWeekDate, subtractOneWeek } from '../../../utils/timeConverter';
import { showSnackbar } from '../../app';
import { API_PATH } from '../constants';
import Overview from '../detailView/Overview'; // FIXME: This hierarchy seems wrong? This seems like its being used as a child but its nested like a sibling?
import { generateBatchApprovalData, generateCandidateHoursData, handleExport } from '../helpers';
import { getPathSelectedTimeCards, getTimeCards, setSelectedTimeCard } from '../reducer';
import TimeKeepingBatchApprovalDrawer from '../TimeKeepingBatchApprovalDrawer/TimeKeepingBatchApprovalDrawer';

import ExportView from './components/ExportViewWrapper';
import TimekeepingPageHeader from './components/TimekeepingPageHeader';
import TimekeepingWeekFilter from './components/TimekeepingWeekFilter';
import { adminRecruiterAdditionalFilters, employerAdditionalFilters } from './additionalFilterData';
import { getDays, getTimekeepingGridColumnData } from './timekeepingGridColumnData';

const RootGrid = styled(Grid)(({ theme }) => ({
  height: `calc(100vh - ${topBarHeight})`,
  padding: theme.spacing(2),
}));

const detailTabData = ({ daysOfWeek, selectedDateEpoch, updateDate, selectedTimeCard }) => [
  {
    tabContent: (
      <Grid container item>
        <Overview
          daysOfWeek={daysOfWeek}
          selectedDate={epochToDateInReadableFormat(selectedDateEpoch)}
          setSelectedDate={updateDate}
          initialData={selectedTimeCard}
        />
      </Grid>
    ),
    tabLabel: 'Overview',
  },
];

const TimekeepingGridView = ({ flags }) => {
  const dispatch = useDispatch();
  const gridContainerRef = useRef();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const timecards = useSelector(selectTimeCards);
  const selectedTimeCard = useSelector(selectSelectedTimeCard);
  const totalRowCount = useSelector(selectTimeSheetGridTotalRowCount);
  const isTimeCardLoading = useSelector(selectTimeCardsIsProcessing);
  const timecardActionIsProcessing = useSelector(selectTimecardActionIsProcessing);
  const user = useSelector(selectUser);
  const token = useMemo(() => user?.token, [user?.token]);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamStart = searchParams.get(searchParamOptions.WEEK_START);
  const searchParamCandidate = searchParams.get(searchParamOptions.CANDIDATE);
  const searchParamDateEpoch = searchParams.get(searchParamOptions.SELECTED_DATE);

  const [selectedDateEpoch, setSelectedDateEpoch] = useState(searchParamDateEpoch);
  const [startEpoch, setStartEpoch] = useState(getSunday(new Date()));

  const [columnData, setColumnData] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);

  const [isPayrollExporting, setIsPayrollExporting] = useState(false);
  const [isPayrollExportOpen, setIsPayrollExportOpen] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (isSearchParamValid(searchParamStart)) {
      const newStartEpoch = getSunday(new Date(parseInt(searchParamStart, 10)));
      if (startEpoch !== newStartEpoch) {
        setStartEpoch(newStartEpoch);
      }
    }
  }, [startEpoch, searchParamStart]);

  useEffect(() => {
    setColumnData(getTimekeepingGridColumnData(startEpoch));
    setDaysOfWeek(getDays(startEpoch));
    return () => {
      dispatch(setSelectedTimeCard(null));
    };
  }, [startEpoch, dispatch]);

  useEffect(() => {
    if (searchParamCandidate && timecards.length > 0) {
      const filterTimecard = timecards.find((card) => card?.candidate?.id === searchParamCandidate);

      // Set the selected timecard equal to the candidates timecard ONLY if it is in grid already
      if (filterTimecard) {
        dispatch(setSelectedTimeCard(filterTimecard));
        return;
      }

      // Note: on reload if selected candidate is on other page, place it on top of timecards after getting details
      const endEpochMili = startEpoch + ONE_WEEK_IN_MS;
      dispatch(
        getPathSelectedTimeCards({
          filters: [
            { field: 'candidate', value: searchParamCandidate, operation: 'equalsID' },
            { field: 'start', value: startEpoch, operation: 'onOrAfter' },
            { field: 'start', value: endEpochMili, operation: 'before' },
          ],
        })
      );
    }
  }, [searchParamCandidate, startEpoch, timecards, dispatch, searchParams]);

  const [rowCount, setRowCount] = React.useState(10);
  const [batchApproveData, setBatchApproveData] = useState({});
  const [isBatchApproveDrawerOpen, setIsBatchApproveDrawerOpen] = useState(false);
  const [selectedCorp, setSelectedCorp] = useState(null);
  const updateDate = useCallback(
    (shortDateString) => {
      const selectedEpoch = shortDateString
        ? dayjs(shortDateString).unix() * ONE_SECOND_IN_MS
        : startEpoch;

      const newParams = {
        [searchParamOptions.WEEK_START]: startEpoch,
        [searchParamOptions.CANDIDATE]: searchParamCandidate,
        [searchParamOptions.SELECTED_DATE]: selectedEpoch,
      };
      setSearchParams(newParams);
      setSelectedDateEpoch(selectedEpoch);
    },
    [startEpoch, searchParamCandidate, setSearchParams]
  );

  const cleanupSelections = useCallback(() => {
    setSearchParams({});
    dispatch(setSelectedTimeCard(null));
  }, [dispatch, setSearchParams]);

  const handleClose = useCallback(() => {
    cleanupSelections();
  }, [cleanupSelections]);

  const gridContainerDims = useDims(gridContainerRef);

  const approveWeek = () => {
    setIsBatchApproveDrawerOpen(true);
    setBatchApproveData(generateBatchApprovalData(timecards, true));
  };

  const timecardPaginationQuery = useCallback(
    (params) => {
      const corpFilter = get('filters', params).filter((i) => i.field === 'corporation');
      setSelectedCorp(corpFilter.length ? first(corpFilter).value : null);

      const endEpochMili = startEpoch + ONE_WEEK_IN_MS;
      const weekParams = {
        filters: [
          { operation: 'onOrAfter', field: 'start', value: startEpoch },
          { operation: 'before', field: 'start', value: endEpochMili },
        ],
      };

      const customFilter = {
        filters: [...get('filters', params), ...get('filters', weekParams)],
      };

      dispatch(getTimeCards({ ...params, ...customFilter }));
    },
    [dispatch, startEpoch]
  );

  const onSelectTimecard = useCallback(
    (selectedTimeObject) => {
      if (timecards && selectedTimeObject) {
        setSearchParams({
          [searchParamOptions.WEEK_START]: startEpoch,
          [searchParamOptions.CANDIDATE]: selectedTimeObject.candidate.id,
          [searchParamOptions.SELECTED_DATE]: startEpoch,
        });

        dispatch(setSelectedTimeCard(selectedTimeObject));
        setSelectedDateEpoch(startEpoch);
      }
    },
    [timecards, startEpoch, dispatch, setSearchParams]
  );

  const handleWeekChange = useCallback(
    (newStartEpoch) => {
      setSearchParams({
        [searchParamOptions.WEEK_START]: newStartEpoch,
        [searchParamOptions.SELECTED_DATE]: newStartEpoch,
      });
      setPage(0);
      const data = getTimekeepingGridColumnData(newStartEpoch);
      setColumnData(data);
      setStartEpoch(newStartEpoch);
      dispatch(setSelectedTimeCard(null));
    },
    [dispatch, setSearchParams]
  );

  const handleBackButtonClick = useCallback(() => {
    handleWeekChange(subtractOneWeek(startEpoch));
  }, [startEpoch, handleWeekChange]);

  const handleForwardButtonClick = useCallback(() => {
    handleWeekChange(addOneWeek(startEpoch));
  }, [startEpoch, handleWeekChange]);

  const shouldUsePayrollV2 = launchDarklyToggles(flags, 'isPayrollV2Enabled');

  const handleOnExportClick = useCallback(async () => {
    if (!shouldUsePayrollV2) {
      if (!isPayrollExporting) {
        try {
          setIsPayrollExporting(true);
          const startEpochMili = startEpoch;
          const endEpochMili = startEpoch + ONE_WEEK_IN_MS;
          const response = await apiGet(
            `payroll-data?startAfter=${startEpochMili}&startBefore=${endEpochMili}`,
            {},
            token
          );

          const csv = convertPayrollDataToCSV(response.data.grid);

          handleExport({
            csv,
            fileName: `payroll_${getWeekDate(startEpoch, true)}`,
          });
        } catch (exceptionVar) {
          dispatch(showSnackbar({ message: 'error.payrollExport' }));
        } finally {
          setIsPayrollExporting(false);
        }
      }
    } else {
      setIsPayrollExportOpen(true);
    }
  }, [dispatch, isPayrollExporting, shouldUsePayrollV2, startEpoch, token]);

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && selectedTimeCard)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight} )`,
            marginRight: selectedTimeCard && 2,
          }}
          ref={gridContainerRef}
        >
          <TimekeepingPageHeader
            user={user}
            handleOnExportClick={handleOnExportClick}
            approveWeek={approveWeek}
            isPayrollExporting={isPayrollExportOpen}
          />

          <Grid
            container
            sx={{
              height: `calc(100% - ${listHeaderHeight})`,
              width: '100%',
            }}
          >
            {timecards && (
              <WaePaginatedDataGrid
                apiPath={API_PATH}
                columnData={columnData}
                additiveFilters={
                  (user?.role === UserRole.EMPLOYER &&
                    employerAdditionalFilters(get(['employer', 'corporation'], user))) ||
                  adminRecruiterAdditionalFilters(selectedCorp)
                }
                disableMultipleSelection
                disableVirtualization
                FilterLeftComponent={
                  <TimekeepingWeekFilter
                    startEpoch={startEpoch}
                    handleBackButtonClick={handleBackButtonClick}
                    handleForwardButtonClick={handleForwardButtonClick}
                  />
                }
                hideFilterBreakpoint={selectedTimeCard ? '2700px' : '0'}
                onSelectionModelChange={onSelectTimecard}
                page={page}
                pageSize={pageSize}
                pagination
                paginatedData={timecards.map((data) => ({
                  ...data,
                  ...generateCandidateHoursData(data, selectedTimeCard),
                  id: data?.candidate?.id,
                  employeeName: data?.candidate?.name,
                  overtimeHours: data?.overtimeHours,
                  regularHours: data?.regularHours,
                  totalHours: data?.totalHours,
                }))}
                paginationQuery={timecardPaginationQuery}
                setPageSize={setPageSize}
                setPage={(p) => {
                  cleanupSelections();
                  setPage(p);
                }}
                setRowCount={setRowCount}
                rowCount={rowCount}
                totalRowCount={totalRowCount}
                sx={{
                  border: 'none',
                  height: '100%',
                  width: '100%',
                }}
                selectionModel={selectedTimeCard?.candidate?.id || []}
              />
            )}
            {selectedTimeCard && daysOfWeek && (
              <DetailView
                close={handleClose}
                sx={{ marginLeft: theme.spacing(1.5), height: gridContainerDims.height }}
                tabData={() =>
                  detailTabData({ daysOfWeek, selectedDateEpoch, updateDate, selectedTimeCard })
                }
              />
            )}
          </Grid>
        </Grid>
      </Slide>
      {!mediumScreen && selectedTimeCard && (
        <DetailView
          close={handleClose}
          sx={{
            width: '520px',
            marginLeft: theme.spacing(1.5),
            height: '100%',
          }}
          tabData={() =>
            detailTabData({ daysOfWeek, selectedDateEpoch, updateDate, selectedTimeCard })
          }
        />
      )}
      {isPayrollExportOpen && (
        <ExportView
          filters={[
            { field: 'start', value: startEpoch, operation: 'onOrAfter' },
            { field: 'start', value: startEpoch + ONE_WEEK_IN_MS, operation: 'before' },
          ]}
          user={user}
          isOpen={isPayrollExportOpen}
          onClose={() => setIsPayrollExportOpen(false)}
        />
      )}
      <TimeKeepingBatchApprovalDrawer
        anchor="right"
        isOpen={isBatchApproveDrawerOpen}
        onClose={() => {
          setIsBatchApproveDrawerOpen(false);
          setBatchApproveData({});
        }}
        submitValue={batchApproveData?.placements || []}
        displayValue={batchApproveData?.nameAndHours || []}
      />
      {(timecardActionIsProcessing || isTimeCardLoading) && <BackdropCircularProgress />}
    </RootGrid>
  );
};
export default withLDConsumer()(TimekeepingGridView);

TimekeepingGridView.propTypes = {
  flags: PropTypes.shape({}),
};
